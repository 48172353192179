import { Node, useEditor } from '@craftjs/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import shortid from 'shortid';
import { z } from 'zod';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useQueryParams } from 'hooks/useQueryParams';
import { APIError, get, post, put } from 'util/httpsClient';

export const editablePageDataSchema = z.object({
  path: z.string(),
  content: z.string(),
});

export type EditablePageData = z.infer<typeof editablePageDataSchema>;

export const useGetEditablePages = () => {
  return useQuery({
    queryKey: ['editablePages'],
    queryFn: () => get({ path: '/editable-pages' }),
    meta: {
      errorMessage: 'Failed to fetch editable pages',
    },
  });
};

export const useGetEditablePage = (path: string, version?: string | number) => {
  return useQuery({
    queryKey: getEditablePageKey(path, version),
    queryFn: getEditablePageQueryFn(path, version),
    meta: {
      errorMessage: 'Failed to fetch editable page',
    },
    retry: (failureCount, error) => {
      if (error instanceof APIError && error?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: !!path,
  });
};

export const getEditablePageKey = (path: string, version?: string | number) => {
  return ['editablePage', path, version] as const;
};

export const getEditablePageQueryFn = (path: string, version?: string | number) => {
  const searchParams = version ? `?version=${version}` : '';

  return () =>
    get({ path: `/editable-pages/${path}${searchParams}` }).then(data =>
      editablePageDataSchema.parse(data)
    );
};

export const useGetEditablePageVersions = () => {
  return useQuery({
    queryKey: ['editablePageVersions'],
    queryFn: () => get({ path: `/editable-pages/versions` }),
    meta: {
      errorMessage: 'Failed to fetch editable page versions',
    },
    retry: (failureCount, error) => {
      if (error instanceof APIError && error?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
  });
};

export const useUpdateEditablePages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newConfig: { path: string; content: string }) => {
      return put({ path: `/editable-pages/${newConfig.path}`, body: newConfig });
    },
    meta: {
      name: 'updateEditablePages',
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['editablePageVersions'] });
      queryClient.invalidateQueries({ queryKey: ['editablePages'] });
      queryClient.invalidateQueries({ queryKey: ['editablePage'] });
    },
    onError: () => {
      toast.error('Failed to update page configuration');
    },
  });
};

export const useCreateEditablePage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newPage: { path: string }) => {
      return post({ path: `/editable-pages`, body: newPage });
    },
    meta: {
      name: 'createEditablePage',
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['editablePageVersions'] });
      queryClient.invalidateQueries({ queryKey: ['editablePages'] });
      queryClient.invalidateQueries({ queryKey: ['editablePage'] });
    },
    onError: () => {
      toast.error('Failed to create new editable page');
    },
  });
};

export const useEditablePagesQueryParams = () => {
  return useQueryParams(
    {
      edit: false,
      version: undefined,
    },
    z.object({
      edit: z.coerce.boolean(),
      version: z.number().optional(),
    })
  );
};

export const useIsNoldUser = () => {
  const { currentUser, isLoading } = useCurrentUser();
  const email = currentUser?.attributes?.email;
  const isNoldEmail = email?.endsWith('@thenold.com') || email?.endsWith('@nold.app');

  return { isNoldEmail: isNoldEmail, isLoading };
};

export const useSetActiveVersion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ path, version }: { path: string; version: string }) => {
      return post({
        path: `/editable-pages/${path}/versions/${version}/activate`,
        body: { active: true },
      });
    },
    meta: {
      name: 'setActiveVersion',
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['editablePageVersions'] });
      queryClient.invalidateQueries({ queryKey: ['editablePages'] });
      queryClient.invalidateQueries({ queryKey: ['editablePage'] });
    },
  });
};

export const useGetCloneTree = () => {
  const { query } = useEditor();
  const getCloneTree = useCallback(
    (idToClone: string) => {
      const tree = query.node(idToClone).toNodeTree();
      const newNodes: Record<string, Node> = {};

      const changeNodeId = (node: Node, newParentId?: string) => {
        const newNodeId = shortid();
        const childNodes = node.data.nodes.map((childId: string) =>
          changeNodeId(tree.nodes[childId], newNodeId)
        );
        const linkedNodes = Object.keys(node.data.linkedNodes).reduce((accum, id) => {
          let newNodeId = '';
          newNodeId = changeNodeId(tree.nodes[node.data.linkedNodes[id]], newNodeId);
          return {
            ...accum,
            [id]: newNodeId,
          };
        }, {});

        const tmpNode = {
          ...node,
          id: newNodeId,
          data: {
            ...node.data,
            parent: newParentId || node.data.parent,
            nodes: childNodes,
            linkedNodes,
          },
        };

        const freshnode = query.parseFreshNode(tmpNode).toNode();
        newNodes[newNodeId] = freshnode;
        return newNodeId;
      };

      const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
      return {
        rootNodeId,
        nodes: newNodes,
      };
    },
    [query]
  );

  return getCloneTree;
};
