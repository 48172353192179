import { Form } from 'react-final-form';

import { FieldTextInput } from 'components';
import { Dialog, DialogContent, DialogFooter } from 'components/_chadcn/Dialog';
import { PrimaryButton } from 'components/Button/Button';
import { useSubscribeToNewsletter } from 'containers/PageBuilder/SectionBuilder/SectionFooter/SectionFooter.hooks';

const NewsletterModal = ({ isOpen, onClose }) => {
  const { mutate: subscribe } = useSubscribeToNewsletter();

  const handleSubmit = values => {
    subscribe({ email: values.email });
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="flex flex-col items-center gap-1 justify-center pt-0 pb-4 px-4 sm:px-6 sm:max-w-[min(600px,calc(100vw-var(--n-size-4)))] max-w-[min(375px,calc(100vw-var(--n-size-4)))] rounded-3xl max-h-[calc(100vh-var(--n-size-4))] overflow-y-auto">
        <div className="relative w-full sm:h-[375px] h-[275px] flex justify-center">
          <img
            src="https://cdn.thenold.com/images/2024-10-22_13-04-48__Marketing-pop-up_compressed.webp"
            alt="Shoe"
            className="sm:w-[300px] w-[250px] object-cover transform sm:-translate-y-4 sm:pt-4 pt-0"
          />

          <h2 className="absolute bottom-0 font-syne text-6 sm:text-8 font-bold text-black text-center mt-10 sm:mt-0">
            10% OFF
          </h2>
        </div>
        <p className="text-center sm:pl-4 sm:pr-4 pb-1 pt-2 text-1">
          Join the NOLD Circle and get 10% off your first order by subscribing to our newsletter
          here 👇
        </p>
        <Form
          onSubmit={handleSubmit}
          render={() => (
            <>
              <FieldTextInput
                name="email"
                validate={value => (value ? undefined : 'Email is required')}
                placeholder="E-mail"
                className="w-full p-1 mt-2 max-w-[450px] font-montserrat"
                autoFocus={false}
              />
              <DialogFooter className="sm:justify-between m-4">
                <PrimaryButton onClick={handleSubmit}>
                  <span className="font-bold">Subscribe now!</span>
                </PrimaryButton>
              </DialogFooter>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewsletterModal;
