import { useLocalStorage } from 'usehooks-ts';

type ModalManagerData = {
  newsletterShowCount: number;
  newsletterLastShownDate: string;
  learnMoreLastShownDate: string;
  hasVisitedArticle: boolean;
};

const useModalManagerData = () => {
  const [modalManagerData, setModalManagerData] = useLocalStorage<ModalManagerData>(
    'modalManager',
    {
      newsletterShowCount: 0,
      newsletterLastShownDate: '',
      learnMoreLastShownDate: '',
      hasVisitedArticle: false,
    }
  );

  const updateModalManagerData = (updates: Partial<ModalManagerData>) => {
    setModalManagerData(prevData => ({ ...prevData, ...updates }));
  };

  return [modalManagerData, updateModalManagerData] as const;
};

export default useModalManagerData;
