import InfiniteScroll from 'react-infinite-scroll-component';

import { DEFAULT_POSTINGS_PAGE_SIZE, Posting, useGetPostings } from './PostingsPage.hooks';
import { IconSpinner, NamedLink } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { cn } from 'util/cn';

export const PostingsGrid = ({
  displayLimit,
  onClickPosting,
  className,
}: {
  displayLimit?: number;
  onClickPosting: (postingId: number) => void;
  className?: string;
}) => {
  const { data, isLoading, error, fetchNextPage, hasNextPage } = useGetPostings({
    pageSize: displayLimit || DEFAULT_POSTINGS_PAGE_SIZE,
  });

  if (error) return <div className="text-center py-4">Error loading postings</div>;

  const postings = data?.pages.flatMap(page => page.postings) || [];
  const postingsToDisplay = displayLimit ? postings.slice(0, displayLimit) : postings;
  const showSeeAll = Boolean(displayLimit);

  return (
    <InfiniteScroll
      className={cn('grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 h-full', className)}
      dataLength={postingsToDisplay.length}
      next={fetchNextPage}
      hasMore={Boolean(hasNextPage)}
      loader={
        <div className="text-center py-8 grid place-items-center col-span-full">
          <IconSpinner />
        </div>
      }
    >
      {isLoading && <div className=""></div>}
      {postingsToDisplay.map((posting, index) => (
        <PostingsGridItem
          key={posting.id}
          posting={posting}
          index={index}
          totalItems={postingsToDisplay.length + (showSeeAll ? 1 : 0)}
          onClick={() => onClickPosting(posting.id)}
          topBorder={showSeeAll}
        />
      ))}
      {showSeeAll && (
        <PostingsGridItemSeeAll
          index={postingsToDisplay.length}
          totalItems={postings.length + 1}
          topBorder
        />
      )}
    </InfiniteScroll>
  );
};

const PostingsGridItem = ({
  posting,
  index,
  totalItems,
  onClick,
  topBorder,
}: {
  posting: Posting;
  index: number;
  totalItems: number;
  onClick?: () => void;
  topBorder?: boolean;
}) => {
  const numCols = useNumCols();

  return (
    <div
      onClick={onClick}
      className={cn(
        'cursor-pointer w-full aspect-square grid place-items-center overflow-hidden',
        !topBorder && index < numCols ? '' : 'border-t',
        index % numCols === numCols - 1 ? '' : 'border-r',
        index >= totalItems - numCols ? 'border-b' : ''
      )}
    >
      <div className="w-full h-full gap-1 sm:gap-2 grid grid-rows-[1fr_auto] p-2 pb-1 sm:pb-2 overflow-hidden">
        <div className="flex justify-center overflow-hidden h-full">
          <img
            srcSet={posting.image?.srcset}
            onError={posting.image?.onError}
            sizes="30vw"
            className="h-full object-contain"
            alt={posting.description}
          />
        </div>
        <p className="text-00 sm:text-0 font-normal text-center">
          {posting.comments.length === 1
            ? '1 response'
            : posting.comments?.length
            ? `${posting.comments.length} responses`
            : 'No responses'}
        </p>
      </div>
    </div>
  );
};

const PostingsGridItemSeeAll = ({
  index,
  totalItems,
  topBorder,
}: {
  index: number;
  totalItems: number;
  topBorder?: boolean;
}) => {
  const numCols = useNumCols();

  return (
    <NamedLink
      name="PostingsPage"
      className={cn(
        'text-00 w-full aspect-square border-b uppercase font-syne font-bold h-full bg-custom-green grid place-items-center text-center',
        !topBorder && index < numCols ? '' : 'border-t',
        index % numCols === numCols - 1 ? '' : 'border-r',
        index >= totalItems - numCols ? 'border-b' : ''
      )}
    >
      <div>See All</div>
    </NamedLink>
  );
};

const useNumCols = () => {
  const isMedium = useMediaQueries({ viewport: 'medium' });
  const isLarge = useMediaQueries({ viewport: 'large' });

  return isLarge ? 5 : isMedium ? 4 : 3;
};
