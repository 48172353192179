import moment from 'moment';

import { type PostingUser as PostingUserType } from './PostingsPage.hooks';
import { cn } from 'util/cn';

export function PostingUser({
  user,
  date,
  className,
  onClick,
}: {
  user: PostingUserType;
  date?: any;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <div className={cn('flex items-center gap-2 p-2', className)} onClick={onClick}>
      {user.imageUrl ? (
        <img src={user.imageUrl || ''} alt={'user'} className="w-6 h-6 rounded-full bg-border" />
      ) : (
        <div className="w-6 h-6 rounded-full bg-border grid place-items-center overflow-hidden p-1">
          <span className="uppercase font-bold text-sm truncate flex-1">
            {user.fullName
              .split(' ')
              .map(name => name[0])
              .join('')}
          </span>
        </div>
      )}
      <span className="font-semibold truncate flex-1">{user.handle}</span>
      {date && <span className="ml-auto text-type-subtle">{moment(date).fromNow()}</span>}
    </div>
  );
}
