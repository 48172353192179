import { useIntl } from 'react-intl';

import { NamedLink } from 'components';
import { cn } from 'util/cn';
import { createListingSlug } from 'util/createListingSlug';
import { formatMoney } from 'util/currency';

export function PostingListingPreview({
  listing,
  disableLink,
}: {
  listing: any;
  disableLink?: boolean;
}) {
  const intl = useIntl();
  return (
    <NamedLink
      name="ListingPage"
      params={{
        id: listing.id.uuid,
        slug: createListingSlug(listing),
      }}
      className={cn('grid grid-cols-[auto_1fr] gap-1', disableLink && 'pointer-events-none')}
    >
      <img
        src={listing.images[0]?.attributes.variants['listing-card-2x'].url}
        className="h-[128px] aspect-[3/4] object-contain"
        alt={listing.attributes.title}
      />
      <div className="grid content-center gap-1">
        <div className="text-000 text-type-subtle uppercase font-bold font-syne">
          {listing.attributes.publicData.brandName}
        </div>
        <div className="font-montserrat text-0 font-normal">{listing.attributes.title}</div>
        <div className="text-0 font-semibold">{formatMoney(intl, listing.attributes.price)}</div>
      </div>
    </NamedLink>
  );
}
