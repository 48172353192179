import * as Dialog from '@radix-ui/react-dialog';
import { motion, PanInfo, useMotionValue } from 'framer-motion';
import React from 'react';

import { IconClose } from 'assets/icons';

const ImageZoomPreview = ({ children, src }: { children: React.ReactNode; src: string }) => {
  const [maxDimensions, setMaxDimensions] = React.useState<{ width: number; height: number }>();
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [isZoomed, setIsZoomed] = React.useState(false);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  React.useEffect(() => {
    if (!imgRef.current) return;

    const img = new window.Image();
    img.src = src;
    img.onload = () => {
      setTimeout(() => {
        const minWidth = Math.min(window.innerWidth, imgRef.current?.offsetWidth || 0);
        const minHeight = Math.min(window.innerHeight, imgRef.current?.offsetHeight || 0);
        setMaxDimensions({
          width: minWidth,
          height: minHeight,
        });
      }, 100);
    };
  }, [src, imgRef.current]);

  const getMaxBounds = (rect: DOMRect) => {
    console.log(maxDimensions);
    const maxMoveX = maxDimensions ? Math.min(rect.width, maxDimensions.width) : 500;
    const maxMoveY = maxDimensions ? Math.min(rect.height, maxDimensions.height) : 500;
    return { maxMoveX, maxMoveY };
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isZoomed) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    const { maxMoveX, maxMoveY } = getMaxBounds(rect);

    // Convert mouse position to range [-1, 1] and scale to max movement
    x.set((mouseX / rect.width - 0.5) * -maxMoveX * 1.2);
    y.set((mouseY / rect.height - 0.5) * -maxMoveY * 1.2);
  };

  const handleDrag = (event: any, info: PanInfo) => {
    if (!isZoomed) return;

    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const { maxMoveX, maxMoveY } = getMaxBounds(rect);

    x.set(Math.max(-maxMoveX, Math.min(maxMoveX, x.get() + info.delta.x)));
    y.set(Math.max(-maxMoveY, Math.min(maxMoveY, y.get() + info.delta.y)));
  };

  const handleToggleZoom = () => {
    setIsZoomed(prev => {
      const isZoomed = !prev;

      if (isZoomed) {
        x.set(0);
        y.set(0);
      }

      return isZoomed;
    });
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed z-50 inset-0 bg-black/90 backdrop-blur-xl"
          onClick={() => {
            alert('clicked');
          }}
        />
        <Dialog.Content className="fixed z-50 inset-0">
          <motion.div
            className="absolute w-full h-full grid place-items-center overflow-hidden"
            onMouseMove={handleMouseMove}
          >
            <motion.img
              src={src}
              alt="Zoomed preview"
              onClick={handleToggleZoom}
              className="object-contain cursor-zoom-in max-w-screen max-h-screen"
              ref={imgRef}
              style={{
                x: isZoomed ? x : 0,
                y: isZoomed ? y : 0,
                scale: isZoomed ? 2 : 1,
              }}
              drag={isZoomed}
              onDrag={handleDrag}
              dragConstraints={{
                left: maxDimensions ? -maxDimensions.width / 1.8 : -500,
                right: maxDimensions ? maxDimensions.width / 1.8 : 500,
                top: maxDimensions ? -maxDimensions.height / 1.8 : -500,
                bottom: maxDimensions ? maxDimensions.height / 1.8 : 500,
              }}
            />
          </motion.div>
          <Dialog.Close className="absolute p-1 md:p-2 top-2 md:top-4 right-2 md:right-4 bg-white/95 backdrop-blur">
            <IconClose className="w-4 h-4" />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ImageZoomPreview;
