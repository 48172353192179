import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { useLogout } from '../auth';
import { del } from 'util/httpsClient';

export const useDeleteCurrentUser = () => {
  const { mutateAsync: logout } = useLogout();

  return useMutation({
    mutationFn: async (password: string) => {
      await del({ path: '/users', body: { password } });
    },
    onSuccess: async () => {
      await logout();
    },
    onError: () => {
      toast.error('Incorrect password');
    },
    meta: {
      name: 'deleteCurrentUser',
    },
  });
};
