import Modal from 'components/Modal/Modal';

const LearnMoreModal = ({ isOpen, onClose, onVisitedArticle }) => {
  return (
    <Modal
      open={isOpen}
      onOpenChange={onClose}
      title=""
      showCloseButton={true}
      closeOnOutsideClick={false}
      contentWrapperClassName="!p-4 !pt-0 grid items-center"
    >
      <div className="grid justify-items-center">
        <div className="max-w-[300px] w-full aspect-square mb-4 rounded-full overflow-hidden">
          <img
            src="https://cdn.thenold.com/images/2024-10-09_09-48-56__founders-team_compressed.webp"
            alt="Team"
            className="object-cover w-full h-full"
            style={{ objectPosition: '50% 30%' }}
          />
        </div>

        <p className="text-3 font-bold text-center uppercase font-syne text-black-700 mb-2">
          THE NEW KID ON THE BLOCK REDEFINES RESALE
        </p>
        <p className="text-0 text-center text-gray-700 mb-5">
          "A new business model for resale that 'makes circularity the first choice' she says, where
          shoppers buy 'second-hand' before considering buying new."
        </p>
        <a
          href="https://www.forbes.com/sites/brookerobertsislam/2024/07/17/how-startup-the-nold-uses-ai--sustainability-to-drive-gen-z-resale/"
          target="_blank"
          rel="noopener noreferrer"
          className="grid justify-items-center gap-1"
          onClick={onVisitedArticle}
        >
          <div className="text-0 font-bold font-syne uppercase">Read more on</div>
          <img
            className="max-w-[120px] h-auto"
            src="https://cdn.thenold.com/images/2024-10-09_12-26-25__forbes-logo_compressed.webp"
            alt="Forbes"
          />
        </a>
      </div>
    </Modal>
  );
};

export default LearnMoreModal;
