import { useQuery } from '@tanstack/react-query';

import { get } from 'util/httpsClient';

export type Brand = {
  id: number;
  name: string;
  partnerId: number | null;
};

export const useGetBrandIdOptions = () => {
  return useQuery({
    queryKey: ['brandIdOptions'],
    queryFn: () => {
      return get({ path: '/configuration' });
    },
    meta: {
      errorMessage: 'Failed to fetch brand id options',
    },
  });
};
