import { useCallback, useEffect, useMemo, useState } from 'react';

import LearnMoreModal from './LearnMoreModal';
import NewsletterModal from './NewsletterModal';
import useModalManagerData from './useModalManagerData';
import { DOBModal } from 'components/Topbar/DOBModal';
import { useIsSubscribedToNewsletter } from 'containers/PageBuilder/SectionBuilder/SectionFooter/SectionFooter.hooks';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { isGuestUser } from 'util/user';

const LEARN_MORE_INTERVAL_DAYS = 30;
const NEWSLETTER_SHOW_INTERVAL_DAYS = 7;
const NEWSLETTER_MAX_SHOWS = 4;

const ModalManager = () => {
  const [modalToShow, setModalToShow] = useState<string | null>(null);
  const [modalManagerData, updateModalManagerData] = useModalManagerData();
  const {
    newsletterShowCount,
    newsletterLastShownDate,
    learnMoreLastShownDate,
    hasVisitedArticle,
  } = modalManagerData;

  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();
  const isGuest = isGuestUser(currentUser);
  const userEmail = currentUser?.attributes?.email;

  const { data: isSubscribed, isLoading: isSubscribedLoading } = useIsSubscribedToNewsletter(
    userEmail,
    !!userEmail && !isGuest
  );

  const handleCloseModal = () => {
    setModalToShow(null);
  };

  const handleVisitedArticle = () => {
    updateModalManagerData({ hasVisitedArticle: true });
    handleCloseModal();
  };

  const modalConfig = useMemo(() => {
    const now = new Date();

    return {
      newsletter: {
        priority: 1,
        maxShows: NEWSLETTER_MAX_SHOWS,
        showIntervalDays: NEWSLETTER_SHOW_INTERVAL_DAYS,
        showPredicate: () => {
          if (!isGuest && isSubscribed) return false;
          if (newsletterShowCount >= NEWSLETTER_MAX_SHOWS) return false;
          if (!newsletterLastShownDate) return true;

          const daysSinceLastShow =
            (now.getTime() - new Date(newsletterLastShownDate).getTime()) / (1000 * 3600 * 24);
          return daysSinceLastShow >= NEWSLETTER_SHOW_INTERVAL_DAYS;
        },
        onShow: () => {
          updateModalManagerData({
            newsletterShowCount: newsletterShowCount + 1,
            newsletterLastShownDate: now.toISOString(),
          });
        },
        onClose: handleCloseModal,
      },
      learnMore: {
        priority: 2,
        maxShows: null,
        showIntervalDays: LEARN_MORE_INTERVAL_DAYS,
        showPredicate: () => {
          if (hasVisitedArticle) return false;
          if (!learnMoreLastShownDate) return true;

          const daysSinceLastShow =
            (now.getTime() - new Date(learnMoreLastShownDate).getTime()) / (1000 * 3600 * 24);
          return daysSinceLastShow >= LEARN_MORE_INTERVAL_DAYS;
        },
        onShow: () => {
          updateModalManagerData({
            learnMoreLastShownDate: now.toISOString(),
          });
        },
        onClose: handleCloseModal,
      },
    };
  }, [
    isGuest,
    isSubscribed,
    newsletterShowCount,
    newsletterLastShownDate,
    hasVisitedArticle,
    learnMoreLastShownDate,
    updateModalManagerData,
  ]);

  const determineModalToShow = useCallback(() => {
    if ((!isGuest && isSubscribedLoading) || isCurrentUserLoading) {
      setTimeout(determineModalToShow, 500);
      return;
    }

    const modals = Object.entries(modalConfig)
      .map(([key, config]) => ({ key, ...config }))
      .sort((a, b) => a.priority - b.priority);

    for (const modal of modals) {
      if (modal.showPredicate()) {
        setModalToShow(modal.key);
        if (modal.onShow) modal.onShow();
        return;
      }
    }

    setModalToShow(null);
  }, [isSubscribedLoading, isCurrentUserLoading, modalConfig]);

  useEffect(() => {
    const acceptButton = document.querySelector('[data-tid="banner-accept"]');
    const declineButton = document.querySelector('[data-tid="banner-decline"]');

    const handleCookieBannerClick = () => {
      determineModalToShow();
    };

    acceptButton?.addEventListener('click', handleCookieBannerClick);
    declineButton?.addEventListener('click', handleCookieBannerClick);

    return () => {
      acceptButton?.removeEventListener('click', handleCookieBannerClick);
      declineButton?.removeEventListener('click', handleCookieBannerClick);
    };
  }, [determineModalToShow]);

  return (
    <>
      <NewsletterModal isOpen={modalToShow === 'newsletter'} onClose={handleCloseModal} />
      <LearnMoreModal
        isOpen={modalToShow === 'learnMore'}
        onClose={handleCloseModal}
        onVisitedArticle={handleVisitedArticle}
      />
      <DOBModal />
    </>
  );
};

export default ModalManager;
