import { PiGridFourThin, PiSquareSplitVerticalThin } from 'react-icons/pi';
import { useHistory } from 'react-router-dom';

import PostingCreateModal from './PostingCreateModal';
import { PostingDetailsDrawer } from './PostingDetailsDrawer';
import { PostingsGrid } from './PostingsGrid';
import PostingsList from './PostingsList';
import { usePostingsQueryParams } from './PostingsPage.hooks';
import { IconChevronLeft, IconPlus } from 'assets/icons';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { cn } from 'util/cn';
import { pathByRouteName } from 'util/routes';

function PostingsPageNew() {
  const history = useHistory();
  const routes = useRouteConfiguration();
  const [{ layout, postingId }, setQueryParams] = usePostingsQueryParams();

  return (
    <div className="w-full h-[100vh] grid grid-rows-[auto_auto_1fr] ">
      <TopbarContainer className="hidden md:block md:border-b" />
      <h1 className="md:hidden uppercase text-center p-3 font-syne relative font-bold text-2 border-b">
        <button
          className="p-3 absolute left-0 bottom-0 top-0"
          onClick={() => history.push(pathByRouteName('LandingPage', routes))}
        >
          <IconChevronLeft />
        </button>
        <span># looking for</span>
      </h1>
      <div className="grid grid-cols-[1fr_auto] md:w-full md:mx-auto md:border-r lg:max-w-screen-lg">
        <PostingCreateModal>
          <div className="text-center uppercase font-syne font-bold flex items-center justify-center gap-2">
            <IconPlus className="bg-custom-green text-black rounded-full" />
            Add looking for
          </div>
        </PostingCreateModal>
        <div className="flex items-center border-l">
          <button
            className={cn('p-1', layout === 'list' ? 'bg-border' : 'text-black')}
            onClick={() => setQueryParams({ layout: 'list' })}
          >
            <PiSquareSplitVerticalThin size={32} />
          </button>
          <button
            className={cn('p-1', layout === 'grid' ? 'bg-border' : 'text-black')}
            onClick={() => setQueryParams({ layout: 'grid' })}
          >
            <PiGridFourThin size={32} />
          </button>
        </div>
      </div>
      <div className="overflow-y-auto border-t">
        {layout === 'grid' ? (
          <PostingsGrid
            onClickPosting={postingId => setQueryParams({ postingId })}
            className="md:w-full md:mx-auto lg:border-x lg:max-w-screen-lg"
          />
        ) : (
          <PostingsList
            onClickPosting={postingId => setQueryParams({ postingId })}
            className="md:w-full md:max-w-screen-md md:mx-auto lg:border-x"
          />
        )}
      </div>
      <PostingDetailsDrawer
        postingId={postingId}
        onClose={() => setQueryParams({ postingId: undefined })}
      />
    </div>
  );
}

export default PostingsPageNew;
