import { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { PiArrowDownLight, PiCircleNotchThin, PiImageLight } from 'react-icons/pi';

import { PostingListingPreview } from './PostingListingPreview';
import { useCreateComment, useGetPosting } from './PostingsPage.hooks';
import { PostingUser } from './PostingsUser';
import { IconClose, IconPlus, IconSend } from 'assets/icons';
import { IconSpinner, ListingCard } from 'components';
import { Popover, PopoverAnchor, PopoverContent } from 'components/_chadcn/Popover';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from 'components/_chadcn/Sheet';
import ImageZoomPreview from 'components/ImageZoomPreview/ImageZoomPreview';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { useGetUserListings } from 'hooks/api/listings/useGetUserListings';
import { useSearchUsers } from 'hooks/api/users';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { cn } from 'util/cn';

export function PostingDetailsDrawer({
  postingId,
  onClose,
}: {
  postingId: number | undefined;
  onClose: () => void;
}) {
  const { data: posting } = useGetPosting(postingId);
  const { data: brandIdOptions } = useGetBrandIdOptions();
  const brandName =
    brandIdOptions && posting
      ? brandIdOptions?.brands?.find(b => b.id === posting.brandId)?.name || 'Unknown Brand'
      : '...';

  const { mutateAsync: createComment, isLoading: isCreatingComment } = useCreateComment();
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const lastWord = inputValue.split(' ').pop();
  const { data: userResults } = useSearchUsers({
    handle: lastWord?.startsWith('@') ? lastWord : undefined,
  });
  const isUserSearchOpen = Boolean(userResults?.length);

  const [images, setImages] = useState<File[]>([]);
  const [isListingSearchOpen, setIsListingSearchOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<any>(null);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const focusInputAtEnd = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  };

  const prevCommentsLengthRef = useRef(0);

  useEffect(() => {
    if (posting?.comments) {
      // Only scroll if new comment added
      if (posting.comments.length > prevCommentsLengthRef.current) {
        const scrollToBottom = () => {
          if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTo({
              top: commentsContainerRef.current.scrollHeight + 1000,
              behavior: 'smooth',
            });
          }
        };
        scrollToBottom();
      }
      prevCommentsLengthRef.current = posting.comments.length;
    }
  }, [posting?.comments]);

  const {
    isLoading: isCurrentUserLoading,
    currentUser: { id: currentUserId },
  } = useCurrentUser();
  const isLoggedIn = !isCurrentUserLoading && currentUserId;

  return (
    <>
      <Popover open={isUserSearchOpen}>
        <Sheet
          open={Boolean(posting)}
          onOpenChange={open => {
            if (!open) {
              onClose();
              setInputValue('');
              setImages([]);
              setSelectedListing(null);
            }
          }}
        >
          <SheetContent
            side="bottom"
            className="max-w-[600px] mx-auto rounded-t-3xl p-2 max-h-[90vh] grid grid-rows-[auto_1fr_auto] gap-2"
            closeClassName="top-[12px]"
          >
            <SheetHeader className="overflow-hidden">
              <SheetTitle className="font-syne font-bold uppercase">Comments & Listings</SheetTitle>
            </SheetHeader>
            <div
              ref={commentsContainerRef}
              className="flex flex-col gap-2 overflow-auto -mx-2 px-2 pb-4"
            >
              {posting && (
                <div className="pb-2 shadow-lg shadow-gray-100 -mx-2 px-2">
                  <PostingUser className="px-0" user={posting.user} date={posting.createdAt} />
                  <div className="mb-1 flex justify-between gap-2 font-syne font-bold uppercase text-type-subtle text-000">
                    <span>{brandName}</span>
                    {posting.size && (
                      <span>
                        <span className="text-type-disabled text-right">Size:</span> {posting.size}
                      </span>
                    )}
                  </div>
                  <ImageZoomPreview src={posting.image?.variants.original.url ?? ''}>
                    <div className="flex flex-col gap-2">
                      <h3 className="line-clamp-2">{posting.description}</h3>
                      <img
                        srcSet={posting.image?.srcset}
                        onError={posting.image?.onError}
                        alt={posting.description}
                        className="h-[min(250px,40vh)] rounded-xl mx-auto object-contain"
                      />
                    </div>
                  </ImageZoomPreview>
                </div>
              )}
              {posting?.comments.length === 0 ? (
                <div className="text-center pb-2 pt-4 font-normal text-type-subtle">
                  No replies yet. <br />
                  Have the same or similar item? <br />
                  Comment below <PiArrowDownLight className="inline" />
                </div>
              ) : (
                <div className="font-syne uppercase font-bold my-1 text-type-subtle">
                  Responses:
                </div>
              )}
              {posting?.comments.map((comment, index) => (
                <div
                  key={comment.id}
                  className={cn('flex flex-col gap-2', index !== 0 && 'border-t')}
                >
                  <PostingUser user={comment.user} date={comment.createdAt} className="px-0 pb-0" />
                  {Boolean(comment.images?.length) && (
                    <div className="flex gap-1 justify-center flex-wrap mb-2">
                      {comment.images?.map((image, index) => {
                        return (
                          <ImageZoomPreview src={image?.variants.original.url ?? ''}>
                            <img
                              key={index}
                              srcSet={image?.srcset}
                              onError={image?.onError}
                              sizes="200px"
                              className="max-w-[100px] flex-1"
                              alt=""
                            />
                          </ImageZoomPreview>
                        );
                      })}
                    </div>
                  )}
                  {Boolean(comment.listing) && <PostingListingPreview listing={comment.listing} />}
                  {Boolean(comment.content) && (
                    <div className="bg-border rounded-xl px-2 py-1">{comment.content}</div>
                  )}
                </div>
              ))}
            </div>
            <Form
              onSubmit={async (values, form) => {
                if (!postingId || !values.comment) return;

                await createComment(
                  {
                    postingId,
                    content: values.comment,
                    images,
                    listingId: selectedListing?.id.uuid,
                  },
                  {
                    onSuccess: () => {
                      form.reset();
                      setInputValue('');
                      setSelectedListing(null);
                      setImages([]);
                    },
                  }
                );
              }}
              render={({ handleSubmit }) => (
                <PopoverAnchor asChild>
                  <form
                    onSubmit={handleSubmit}
                    className={cn(
                      '-m-2 p-2 gap-2 flex flex-col overflow-hidden shadow-[0_0_16px_rgba(0,0,0,0.075)]',
                      !isLoggedIn && 'opacity-50 pointer-events-none'
                    )}
                  >
                    {Boolean(images.length) && (
                      <div className="relative">
                        <div className="flex gap-1 overflow-auto w-full">
                          {images.map((image, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              className="max-h-[100px] max-w-[200px] object-contain"
                              alt=""
                            />
                          ))}
                          <button
                            type="button"
                            className="absolute -top-2 -right-2 p-2 bg-white/50 backdrop-blur rounded-bl-lg"
                            onClick={() => setImages([])}
                            disabled={!isLoggedIn}
                          >
                            <IconClose />
                          </button>
                        </div>
                      </div>
                    )}
                    {Boolean(selectedListing) && (
                      <div className="relative">
                        <PostingListingPreview listing={selectedListing} disableLink />
                        <button
                          type="button"
                          className="absolute -top-2 -right-2 p-2"
                          onClick={() => setSelectedListing(null)}
                          disabled={!isLoggedIn}
                        >
                          <IconClose />
                        </button>
                      </div>
                    )}
                    <div className="flex items-center gap-1 transition-colors">
                      <button
                        type="button"
                        className="w-6 h-6 rounded-full bg-black disabled:bg-border transition-colors text-white disabled:text-type-disabled grid place-items-center"
                        onClick={() => setIsListingSearchOpen(true)}
                        disabled={!isLoggedIn || Boolean(images.length)}
                      >
                        <IconPlus />
                      </button>
                      <label
                        className="cursor-pointer w-6 h-6 rounded-full bg-black aria-disabled:bg-border transition-colors text-white aria-disabled:text-type-disabled grid place-items-center"
                        aria-disabled={!isLoggedIn || Boolean(selectedListing)}
                      >
                        <PiImageLight size={24} />
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={e => setImages(Array.from(e.target.files || []))}
                          className="w-full hidden"
                          disabled={!isLoggedIn || Boolean(selectedListing)}
                        />
                      </label>
                      <Field name="comment">
                        {({ input }) => (
                          <label className="flex-1 flex items-center gap-1 bg-border rounded-full ">
                            <input
                              {...input}
                              ref={inputRef}
                              type="text"
                              disabled={isCreatingComment || !isLoggedIn}
                              placeholder="Comment (required)"
                              className="border-none py-1 px-2 w-full"
                              onChange={e => {
                                input.onChange(e);
                                handleInputChange(e.target.value);
                              }}
                              value={inputValue}
                            />
                            <button
                              type="submit"
                              className="h-[48px] w-[48px] grid place-items-center flex-shrink-0 disabled:text-type-disabled"
                              disabled={isCreatingComment || !isLoggedIn || !inputValue}
                            >
                              {isCreatingComment ? (
                                <PiCircleNotchThin size={24} className="animate-spin text-black" />
                              ) : (
                                <IconSend />
                              )}
                            </button>
                          </label>
                        )}
                      </Field>
                    </div>
                  </form>
                </PopoverAnchor>
              )}
            />
          </SheetContent>
        </Sheet>
        <PopoverContent
          className="min-w-[50vw] shadow-none p-2"
          onOpenAutoFocus={e => e.preventDefault()}
        >
          <div className="flex flex-col gap-2">
            {userResults?.map(user => (
              <PostingUser
                key={user.id}
                user={user}
                className="p-0"
                onClick={e => {
                  e.stopPropagation();
                  const valueWithoutLastWord = inputValue.split(' ').slice(0, -1).join(' ');

                  const newValue = `${[valueWithoutLastWord, `@${user.handle}`]
                    .filter(Boolean)
                    .join(' ')} `;
                  setInputValue(newValue);

                  setTimeout(() => {
                    // (to make sure DOM is updated)
                    focusInputAtEnd();
                  }, 0);
                }}
              />
            ))}
          </div>
        </PopoverContent>
      </Popover>
      <ListingSearchSheet
        open={isListingSearchOpen}
        onOpenChange={open => !open && setIsListingSearchOpen(false)}
        onSelectListing={setSelectedListing}
      />
    </>
  );
}

function ListingSearchSheet({
  open,
  onOpenChange,
  onSelectListing,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSelectListing: (listing: any) => void;
}) {
  const { currentUser } = useCurrentUser();
  const [listingSearchTerm, setListingSearchTerm] = useState('');
  const {
    data: listingSearchData,
    isLoading: isListingSearchLoading,
    // TODO: Implement pagination
    fetchNextPage: fetchNextListingSearchPage,
    hasNextPage: hasNextUserListingsPage,
  } = useGetUserListings({
    userId: currentUser?.id?.uuid,
    enabled: open,
    searchTerm: listingSearchTerm,
    publishedOnly: true,
  });

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="bottom"
        className="max-w-[600px] mx-auto rounded-t-3xl p-2 max-h-[90vh] grid grid-rows-[auto_1fr_auto] gap-2"
        closeClassName="top-[12px]"
      >
        <SheetHeader>
          <SheetTitle className="font-syne font-bold uppercase">Your Listings</SheetTitle>
        </SheetHeader>
        <div className="grid grid-cols-2 gap-2 overflow-auto">
          {listingSearchData?.pages.map(page =>
            page.listings.map(listing => (
              <ListingCard
                key={listing.id}
                listing={listing}
                onClick={() => {
                  onSelectListing(listing);
                  onOpenChange(false);
                }}
              />
            ))
          )}
          {!isListingSearchLoading &&
            listingSearchData?.pages.length === 1 &&
            listingSearchData?.pages[0].listings.length === 0 && (
              <div className="col-span-full flex justify-center items-center px-2 py-4 min-h-[150px] text-type-subtle">
                <p>No listings found</p>
              </div>
            )}
          {isListingSearchLoading && (
            <div className="col-span-full flex justify-center items-center px-2 py-4 min-h-[150px]">
              <IconSpinner />
            </div>
          )}
        </div>
        <div className="flex items-center gap-2 shadow-[0_0_16px_rgba(0,0,0,0.075)] -m-2 p-2">
          <label className="flex-1 flex items-center gap-1 bg-border rounded-full ">
            <input
              type="text"
              placeholder="Filter listings"
              className="border-none py-1 px-2 w-full"
              onChange={e => {
                setListingSearchTerm(e.target.value);
              }}
              value={listingSearchTerm}
            />
          </label>
        </div>
      </SheetContent>
    </Sheet>
  );
}
