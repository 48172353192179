import classNames from 'classnames';
import React, { memo } from 'react';
import { useEffect } from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';

import ValidationError from '../ValidationError/ValidationError';

const PhoneField = ({
  input: { onChange, onFocus, onBlur, value },
  className,
  label,
  customErrorText,
  meta,
  country,
  ...rest
}) => {
  const { invalid, touched, error } = meta;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const inputClassName = classNames(className, { 'input-error': hasError });

  return (
    <div className={inputClassName}>
      {label && <label>{label}</label>}
      <div
        // @ts-expect-error TS(2339) FIXME: Property 'tabIndex' does not exist on type 'Readonly<... Remove this comment to see the full error message
        tabindex="0"
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <PhoneInput value={value} onChange={onChange} defaultCountry={country} {...rest} />
      </div>
      {hasError && <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

const InternationalPhoneNumberInput = props => {
  const { name, label, country, validate } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-phone-number-input/style.css');
    }
  }, []);

  return (
    <Field
      name={name}
      label={label}
      validate={validate}
      render={({ input, meta }) => (
        <PhoneField
          {...props}
          input={{ ...input, value: input.value || '' }}
          meta={meta}
          country={country}
        />
      )}
    />
  );
};

export default InternationalPhoneNumberInput;
