import { PiChatsCircleThin } from 'react-icons/pi';

import { Posting } from './PostingsPage.hooks';
import { PostingUser } from './PostingsUser';
import ImageZoomPreview from 'components/ImageZoomPreview/ImageZoomPreview';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { cn } from 'util/cn';

interface PostingItemProps {
  className?: string;
  posting: Posting;
  onClickPosting: (postingId: number) => void;
  compact?: boolean;
}

export const PostingItem = ({
  className,
  posting,
  onClickPosting,
  compact = false,
}: PostingItemProps) => {
  const { data: brandIdOptions } = useGetBrandIdOptions();
  const brandName = brandIdOptions
    ? brandIdOptions?.brands?.find(b => b.id === posting.brandId)?.name || 'Unknown Brand'
    : '...';

  return (
    <div
      className={cn(
        'h-full grid grid-rows-[auto_auto_1fr_auto] cursor-pointer first:border-t-0 border-t overflow-hidden',
        className
      )}
      onClick={() => onClickPosting(posting.id)}
    >
      <PostingUser user={posting.user} date={posting.createdAt} />
      <div className="w-full px-2 mb-1 flex justify-between gap-2 font-syne font-bold uppercase text-type-subtle text-000">
        <span className={compact ? 'line-clamp-1' : ''}>{brandName}</span>
        {posting.size && (
          <span className={compact ? 'line-clamp-1' : ''}>
            <span className="text-type-disabled text-right">Size:</span> {posting.size}
          </span>
        )}
      </div>
      <div className="px-2 grid grid-rows-[auto_1fr] gap-2 overflow-hidden">
        <h3 className="line-clamp-2">{posting.description}</h3>
        <div className="overflow-hidden">
          <img
            srcSet={posting.image?.srcset}
            sizes=""
            onError={posting.image?.onError}
            alt={posting.description}
            className="rounded-xl mx-auto object-contain h-full w-full max-h-[300px]"
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-1 ">
        <button className="p-2 pr-1 flex items-center text-left gap-1 font-medium">
          <PiChatsCircleThin size={28} />
          <span className="text-nowrap">Comments & Listings</span>
        </button>
        <span className="text-type-subtle pr-2 py-2 text-right">
          {posting.comments?.length === 0
            ? 'No replies'
            : posting.comments?.length === 1
            ? 'See reply'
            : `${posting.comments?.length} replies`}
        </span>
      </div>
    </div>
  );
};
