export interface PostingResponse {
  id: number;
  description: string;
  imageUrls: string[];
  comments: CommentResponse[];
  user: UserSummary;
  brandId?: number;
  size?: string;
  createdAt: Date;
}

export interface PostingRequest {
  brandId?: number;
  size?: string;
  description: string;
  imageUrls?: string[];
}

export interface CommentResponse {
  id: number;
  content: string;
  postingId: number;
  userId: string;
  imageUrls: string[];
  createdAt: Date;
  user: UserSummary;
  listingId?: string;
  listing?: any;
}

export interface CommentRequest
  extends Pick<CommentResponse, 'postingId' | 'content' | 'imageUrls' | 'listingId'> {}

export type GetPostingsParams = {
  page?: number;
  pageSize?: number;
};

export enum ImageType {
  Posting = 'posting',
  Comment = 'comment',
}

export type CreatePostingParams = {
  description: string;
  brandId?: number;
  size: string;
  image?: File;
};

export type CreateCommentParams = {
  postingId: number;
  content: string;
  listingId?: string;
  images: File[];
};

export interface UserSummary {
  id: string;
  handle: string;
  fullName: string;
  imageUrl: string;
}
