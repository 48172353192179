import classNames from 'classnames';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedRedirect,
  Page,
  TabsLayout,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './LegalPage.module.css';
import TermsAndConditions from './TermsAndConditions';
import FooterComponent from 'containers/FooterContainer/FooterContainer';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';

const TERMLY_PRIVACY_POLICY_ID = 'b2e98899-9851-4722-afda-d10da025ac59';
const TERMLY_COOKIE_POLICY_ID = 'd0903aaa-eb58-4213-b954-4a0bc81cb8bd';

export const LegalPageTab = {
  TermsAndConditions: 'terms-and-conditions',
  PrivacyPolicy: 'privacy-policy',
  Cookies: 'cookies',
  FAQs: 'FAQs',
} as const;
// TODO: Zod validate
type LegalPageTab = (typeof LegalPageTab)[keyof typeof LegalPageTab];

export const ProfileSettingsPage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
  const scrollingDisabled = useIsScrollingDisabled();
  const { tab } = useParams<{ tab: LegalPageTab }>();

  if (!tab) {
    return <NamedRedirect name="LegalPage" params={{ tab: LegalPageTab.TermsAndConditions }} />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="ProfileSettingsPage" />}
        footer={<MobileBottomNavigation />}
      >
        <TabsLayout<LegalPageTab>
          title="NOLD"
          tab={tab}
          defaultTab={LegalPageTab.TermsAndConditions}
          tabs={[
            {
              title: 'Terms & Conditions',
              value: LegalPageTab.TermsAndConditions,
              linkProps: { name: 'LegalPage', params: { tab: LegalPageTab.TermsAndConditions } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <TermsAndConditions />
                </div>
              ),
            },
            {
              title: 'Privacy Policy',
              value: LegalPageTab.PrivacyPolicy,
              linkProps: { name: 'LegalPage', params: { tab: LegalPageTab.PrivacyPolicy } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <TermlyEmbed id={TERMLY_PRIVACY_POLICY_ID} />
                </div>
              ),
            },
            {
              title: 'Cookies',
              value: LegalPageTab.Cookies,
              linkProps: { name: 'LegalPage', params: { tab: LegalPageTab.Cookies } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <TermlyEmbed id={TERMLY_COOKIE_POLICY_ID} />
                </div>
              ),
            },
            {
              title: 'FAQs',
              value: LegalPageTab.FAQs,
              // linkProps: { name: 'LegalPage', params: { tab: LegalPageTab.FAQs } },
              href: 'https://intercom.help/thenold/en/',
              content: <div />,
            },
          ]}
        />
        <FooterComponent />
      </LayoutSingleColumn>
    </Page>
  );
};

const TermlyEmbed = ({ id }: { id: string }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      // @ts-ignore-next
      name="termly-embed"
      data-id={id}
    />
  );
};

export default ProfileSettingsPage;
