import InfiniteScroll from 'react-infinite-scroll-component';

import { PostingItem } from './PostingItem';
import { useGetPostings } from './PostingsPage.hooks';
import { IconSpinner } from 'components';
import { cn } from 'util/cn';

const PostingsList = ({
  onClickPosting,
  className,
}: {
  onClickPosting: (postingId: number) => void;
  className?: string;
}) => {
  const { data, isLoading, error, fetchNextPage, hasNextPage } = useGetPostings();
  const postings = data?.pages.flatMap(page => page.postings) || [];

  if (isLoading) return null;
  if (error) return <div className="text-center py-4">Error loading postings</div>;

  return (
    <div className={cn('flex flex-col font-normal', className)}>
      <InfiniteScroll
        dataLength={postings.length}
        next={fetchNextPage}
        hasMore={Boolean(hasNextPage)}
        loader={
          <div className="text-center py-4 grid place-items-center">
            <IconSpinner />
          </div>
        }
      >
        {postings.map(posting => (
          <PostingItem key={posting.id} posting={posting} onClickPosting={onClickPosting} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default PostingsList;
