const MAX_WIDTH_PX = 3000;
const MAX_HEIGHT_PX = 3000;

export async function convertToWebpAndCompress(file: File): Promise<File> {
  const { readAndCompressImage } = await import('browser-image-resizer');

  const processedBlob = await readAndCompressImage(file, {
    quality: 0.9,
    maxWidth: MAX_WIDTH_PX,
    maxHeight: MAX_HEIGHT_PX,
    debug: false,
    mimeType: 'image/webp',
  });

  return new File([processedBlob], file.name.replace(/\.[^/.]+$/, '.webp'), {
    type: 'image/webp',
  });
}
