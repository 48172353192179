import { useState } from 'react';

import css from './DeleteAccountModal.module.css';
import { Button, Modal, PrimaryButton } from 'components';
import { TextInput } from 'components/FieldTextInputV2/FieldTextInput';
import { useDeleteCurrentUser } from 'hooks/api/users/useDeleteCurrentUser';

type DeleteAccountModalProps = {
  open: boolean;
  onClose: () => void;
};

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ open, onClose }) => {
  const [password, setPassword] = useState('');

  const { mutateAsync: deleteAccount, isLoading } = useDeleteCurrentUser();

  return (
    <Modal
      title="Delete Account"
      open={open}
      onOpenChange={onClose}
      contentWrapperClassName={css.content}
    >
      <div className={css.inputContainer}>
        <TextInput
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          placeholder="Enter your password to confirm account deletion"
        />
      </div>
      <div className={css.buttonsContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <PrimaryButton
          className={css.deleteButton}
          inProgress={isLoading}
          onClick={async () => {
            await deleteAccount(password);
            onClose();
          }}
        >
          DELETE
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
