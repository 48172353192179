import { z } from 'zod';

import { DEFAULT_SORTING } from '../../util/constants';

const stringArray = z.array(z.string());
const productTypesSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const productSubtypesSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const colorsSchema = z
  .union([z.string(), z.array(z.string())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;
const sizesSchema = z
  .union([z.string(), z.number(), z.array(z.string().or(z.number()))])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof stringArray;

const numbersArray = z.array(z.number());
const brandIdsSchema = z
  .union([z.number(), z.array(z.number())])
  .transform(v => (Array.isArray(v) ? v : [v])) as unknown as typeof numbersArray;

export const filtersQueryParamsSchema = z.object({
  brandIds: brandIdsSchema,
  category: z.string(),
  // TODO: Should be enum
  sortBy: z.string(),
  department: z.string(),
  condition: z.string(),
  productType: productTypesSchema,
  productSubtype: productSubtypesSchema,
  colors: colorsSchema,
  priceRange: z.tuple([z.coerce.number(), z.coerce.number()]),
  text: z.string(),
  sizes: sizesSchema,
  availability: z.enum(['all', 'hide_sold']),
  shipsTo: z.string(),
  preloved: z.boolean(),
  userId: z.string(),
});

export type FiltersQueryParams = z.infer<typeof filtersQueryParamsSchema>;

export const DEFAULT_PRICE_RANGE = [0, 10000] as const;

export const RESETTABLE_FILTERS_DEFAULTS: Pick<
  FiltersQueryParams,
  | 'department'
  | 'productType'
  | 'productSubtype'
  | 'condition'
  | 'colors'
  | 'priceRange'
  | 'text'
  | 'brandIds'
  | 'sizes'
  | 'preloved'
  | 'shipsTo'
  | 'userId'
> = {
  text: '',
  department: '',
  productType: [],
  productSubtype: [],
  colors: [],
  condition: '',
  priceRange: [...DEFAULT_PRICE_RANGE],
  brandIds: [],
  sizes: [],
  preloved: false,
  shipsTo: '',
  userId: '',
};

export const FILTERS_DEFAULTS: FiltersQueryParams = {
  ...RESETTABLE_FILTERS_DEFAULTS,
  category: '',
  // TODO: Should be type-checked
  sortBy: DEFAULT_SORTING,
  availability: 'all',
};
